import React, { useEffect, useState } from "react";
// import navbarlogo from "../../assets/images/navbarlogo.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { HiPlus } from "react-icons/hi";
import { FiAlignRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { fetchNavbarData } from "../../api/API";
import NavbarLoader from "../../common/NavbarLoader/NavbarLoader";
import "./Navbar.css";
import ImageComponentNavBar from "../Home/ImageComponentNavBar";
import Header from "../Header/Header";

function Navbar() {
  const [activeNavLink, setActiveNavLink] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [siteName, setSiteName] = useState();
  const [donation, setdonation] = useState();
  const [publicationList, setPublicationList] = useState();
  const [activityId, setActivityId] = useState();
  const [navActivityIds, setNavActivityIds] = useState([]);
  const [navbarLogo, setNavbarLogo] = useState();
  const [isNavbarLogoLoaded, setIsNavbarLogoLoaded] = useState(false);
  const [pages, setPages] = useState();
  const [site, setSite] = useState({});

  const navigate = useNavigate();

  const handleClickDonation = () => {
    if (site.donation_site === "own_website") {
      navigate("/donation");
    } else if (
      site.donation_site === "other_website" &&
      site.donation_page_url
    ) {
      window.open(site.donation_page_url, "_blank");
    }
  };

  useEffect(() => {
    console.log("activityId", activityId);
  }, [activityId]);

  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchNavbarData({
          url: process.env.REACT_APP_API_URL,
        });
        if (response.data.status == true) {
          setApiData(response.data.responseBody.nav_activity);
          setSiteName(response.data.responseBody.site.name);
          localStorage.setItem(
            "siteName",
            response.data.responseBody.site.name
          );
          setdonation(response.data.responseBody.site.donation);
          setPublicationList(response.data.responseBody.nav_publication);
          setNavbarLogo(response.data.responseBody.site.logo);
          setSite(response.data.responseBody.site);
          console.log("logo", response.data.responseBody.site.logo);
          setIsNavbarLogoLoaded(true);
          console.log(
            "response.data.responseBody.nav_publication",
            response.data.responseBody?.nav_publication
          );
          setActivityId(response?.data?.responseBody?.nav_activity);
          setPages(response?.data?.responseBody?.pages);
          console.log("pages.", response?.data?.responseBody?.pages);
          console.log(
            "activityIdactivityId",
            response?.data?.responseBody?.nav_activity
          );
          console.log(
            "response.data.responseBody.site",
            response.data.responseBody
          );
           //Google analytics script add in index.html file
          const googleAnalyticsScript = response.data.responseBody.site.google_analytics;
          console.log("googleAnalyticsScript", googleAnalyticsScript);
          if (googleAnalyticsScript) {
            // Remove any existing script tag if it was previously added
            const existingScript = document.getElementById('google-analytics-script');
            if (existingScript) {
              existingScript.remove();
            }
            // Clean the script content and add it to the document
            const cleanedScript = googleAnalyticsScript.replace(/<\/?script[^>]*>/g, '').trim();
            const scriptElement = document.createElement('script');
            scriptElement.id = 'google-analytics-script';
            scriptElement.type = 'text/javascript';
            scriptElement.appendChild(document.createTextNode(cleanedScript));
            document.body.appendChild(scriptElement);
            // Execute Google Analytics (if window.ga exists)
            if (window.ga) {
              window.ga('set', 'page', location.pathname); // Set the current page
              window.ga('send', 'pageview'); // Send the pageview event
            }
            console.log('Initial page load:', location.pathname); // Logs the initial path
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //Google analytics script add in index.html file
  useEffect(() => {
    // Remove and re-inject the analytics script when the route changes
    const existingScript = document.getElementById('google-analytics-script');
    if (existingScript) {
      existingScript.remove();
    }

    // Trigger the 'I'm here' log on route change
    console.log("I'm here on route change:", location.pathname);

    // Re-inject the script on route change
    const googleAnalyticsScript = `<script>console.log('Im here');</script>`;
    const cleanedScript = googleAnalyticsScript.replace(/<\/?script[^>]*>/g, '').trim();
    const scriptElement = document.createElement('script');
    scriptElement.id = 'google-analytics-script';
    scriptElement.type = 'text/javascript';
    scriptElement.appendChild(document.createTextNode(cleanedScript));
    document.body.appendChild(scriptElement);

    // Execute Google Analytics (if window.ga exists)
    if (window.ga) {
      window.ga('set', 'page', location.pathname); // Set the new page
      window.ga('send', 'pageview'); // Send the pageview event
    }
  }, [location]);


  useEffect(() => {
    console.log("apiData", apiData);
  }, [apiData]);

  // const location = useLocation();

  const containsActivity = (name) => {
    return location.pathname.includes(name);
  };

  useEffect(() => {
    const pathName = location.pathname;
    setActiveNavLink(pathName === "/audio-list");
    console.log("activeNavLink", activeNavLink);
  }, [location]);

  const handlePageClick = async (id, name, website_page, pageUrl) => {
    if (website_page === "other_website") {
      window.open(pageUrl, "_blank");
    } else {
      navigate(`/custome-page/${name}/${id}`);
    }
  };

  const closeOffcanvas = () => {
    const offcanvasElement = document.querySelector(".offcanvas");
    const offcanvasInstance =
      window.bootstrap.Offcanvas.getInstance(offcanvasElement);
    offcanvasInstance.hide();
  };

  // const handleNavLinkClick = (path) => {
  //   navigate(path);
  //   closeOffcanvas();
  // };

  //working - home link
  // const handleNavLinkClick = (path) => {
  //   navigate(path);
  //   closeOffcanvas();
  //   if (path === "/") {
  //     const homeLink = document.querySelector('.nav-link[href="/]');
  //     if (path === "/") {
  //       const homeLink = document.querySelector('.nav-link[href="/"]');
  //       if (homeLink) {
  //         homeLink.classList.add("active");
  //       }
  //     } else {
  //       const activeLinks = document.querySelectorAll(".nav-link.active");
  //       activeLinks.forEach((link) => link.classList.remove("active"));
  //     }
  //   }
  // };

  //2
  // const handleNavLinkClick = (path) => {
  //   navigate(path);
  //   closeOffcanvas();

  //   const activeLinks = document.querySelectorAll(".nav-link.active");
  //   activeLinks.forEach((link) => link.classList.remove("active"));

  //   const clickedLink = document.querySelector(`.nav-link[href="${path}"]`);
  //   if (clickedLink) {
  //     clickedLink.classList.add("active");
  //   }
  // };

  // working with dropdown
  const handleNavLinkClick = (path, isDropdown) => {
    navigate(path);

    if (!isDropdown) {
      closeOffcanvas();
    }

    const activeLinks = document.querySelectorAll(".nav-link.active");
    activeLinks.forEach((link) => link.classList.remove("active"));

    const clickedLink = document.querySelector(`.nav-link[href="${path}"]`);
    if (clickedLink) {
      clickedLink.classList.add("active");
    }
  };

  return (
    <>
      <Header
        donation={donation}
        handleClickDonation={handleClickDonation}
        pages={pages}
      />
      <nav class="navbar">
        <div class="container-fluid">
          <div className="navbar-right-wrap">
            <NavLink className="navbar-brand" to="/">
              {/* {!isNavbarLogoLoaded && <NavbarLoader />} */}
              <ImageComponentNavBar
                src={navbarLogo}
                alt="logo"
                className="navbar-logo"
              // onLoad={() => setIsNavbarLogoLoaded(true)}
              />
            </NavLink>
            <div className="nav-text">
              {/* <h6>Brahmanand SanskarDham</h6> */}
              {/* <h6>{siteName}</h6> */}
            </div>
          </div>
          <button
            class="navbar-toggler d-xl-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <FiAlignRight className="toggler-iscon" />
          </button>

          <div className="navbar-wrap">
            <div className="navbar-text1 nav-elements">
              <ul className="nav-link-wrap">
                <li className="nav-item active">
                  <NavLink to="/" className="nav-link">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item dropdown">
                  <NavLink
                    className={`nav-link ${containsActivity("activities") ? "active" : "no-active"
                      }`}
                    to={`/activities`}
                  >
                    Activities
                    <HiPlus className="nav-icon" />
                  </NavLink>

                  <ul className="dropdown-menu">
                    {Object.values(apiData).map((item) => (
                      <li key={item.id}>
                        <NavLink
                          className="dropdown-item"
                          to={`/activities/${item.id}/${item.name}`}
                        >
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>

                <li className="nav-item dropdown">
                  <NavLink
                    className={`nav-link ${containsActivity("publication-detail")
                      ? "active"
                      : "no-active"
                      }`}
                    to={
                      publicationList &&
                      `/publication-detail/${publicationList[0].id}/${publicationList[0].name}`
                    }
                  >
                    Publications
                    <HiPlus className="nav-icon" />
                  </NavLink>

                  <ul className="dropdown-menu">
                    {publicationList &&
                      publicationList.length > 0 &&
                      publicationList.map((item) => (
                        <li key={item.id}>
                          <NavLink
                            className="dropdown-item"
                            to={`/publication-detail/${item.id
                              }/${item.name.toLowerCase()}`}
                          >
                            {item.name}
                          </NavLink>
                        </li>
                      ))}
                  </ul>
                </li>
                <li className="nav-item active">
                  <NavLink to="/branches" className="nav-link">
                    Branches
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    to="/about-us"
                    className={`nav-link ${activeNavLink ? "active" : ""}`}
                  >
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/contact-us" className="nav-link">
                    Contact Us
                  </NavLink>
                </li>
                {pages &&
                  Object.values(pages).map((page) => {
                    return page.highlight === "1" ? (
                      <button
                        className="nav-item nav-link donation"
                        onClick={() =>
                          handlePageClick(
                            page.id,
                            page.name,
                            page.website_page,
                            page.page_url
                          )
                        }
                      >
                        {page.name}
                      </button>
                    ) : (
                      ""
                    );
                  })}
              </ul>
            </div>
          </div>

          {/* ===============  offcanvas =============== */}

          <div
            class="offcanvas offcanvas-start"
            tabindex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
                <img src={navbarLogo} alt="logo" className="navbar-logo" />
              </h5>
              <button
                type="button"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body nav-elements">
              <ul class="navbar-nav">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    onClick={() => handleNavLinkClick("/")}
                  >
                    Home
                  </a>
                </li>

                {/* Activities Dropdown */}
                <li className="nav-item dropdown">
                  <NavLink
                    // className="nav-link"
                    className={`nav-link ${containsActivity("activities") ? "active" : "no-active"
                      }`}
                    data-bs-toggle="dropdown"
                  // onClick={() => handleNavLinkClick("/activities", true)}
                  //
                  // href="/activities"
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   handleNavLinkClick("/activities", true);
                  // }}
                  >
                    Activities <HiPlus className="nav-icon" />
                  </NavLink>

                  <ul className="dropdown-menu">
                    {Object.values(apiData).map((item) => (
                      <li key={item.id}>
                        <a
                          className="dropdown-item"
                          onClick={(e) => {
                            e.preventDefault();
                            handleNavLinkClick(
                              `/activities/${item.id}/${item.name}`,
                              false
                            );
                          }}
                          href={`/activities/${item.id}/${item.name}`}
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>

                {/* Publications Dropdown */}
                <li className="nav-item dropdown">
                  <NavLink
                    // to="/publication-detail"
                    // className="nav-link"
                    className={`nav-link ${containsActivity("publication-detail")
                      ? "active"
                      : "no-active"
                      }`}
                    data-bs-toggle="dropdown"
                  // onClick={(e) => {
                  //   e.preventDefault();
                  //   handleNavLinkClick("/publication-detail", true);
                  // }}
                  >
                    Publications
                    <HiPlus className="nav-icon" />
                  </NavLink>

                  <ul className="dropdown-menu">
                    {publicationList &&
                      publicationList.length > 0 &&
                      publicationList.map((item) => (
                        <li key={item.id}>
                          <a
                            className="dropdown-item"
                            onClick={(e) => {
                              e.preventDefault();
                              handleNavLinkClick(
                                `/publication-detail/${item.id
                                }/${item.name.toLowerCase()}`,
                                false
                              );
                            }}
                            href={`/publication-detail/${item.id
                              }/${item.name.toLowerCase()}`}
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    onClick={() => handleNavLinkClick("/branches")}
                  >
                    Branches
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    onClick={() => handleNavLinkClick("/about-us")}
                  >
                    About Us
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    onClick={() => handleNavLinkClick("/contact-us")}
                  >
                    Contact Us
                  </a>
                </li>

                {pages &&
                  Object.values(pages).map((page) => {
                    return page.highlight === "1" ? (
                      <a className="nav-item nav-link donation" href="#">
                        {page.name}
                      </a>
                    ) : (
                      ""
                    );
                  })}

                {/* // {donation === "1" ? (
                //   <a class="nav-link donation responsive" href="#">
                //     Donate Now
                //   </a>
                // ) : (
                //   ""
                // )} */}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
